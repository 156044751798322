import React, { Component } from 'react';

export default class Roadmap extends Component {
	render() {
		return <div className="section padding-top-bottom-big background-gradient-dark" id="roadmap">
			{/* <div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="title-wrap text-center">
							<div className="back-title">ROADMAP</div>
							<h3 className="text-white">Road map and perks.</h3>
							<p className="color-pr">All-in-one app to secure, manage and exchange blockchain assets.</p>
						</div>
					</div>
				</div>
			</div> */}
			<div className="container">
				<div className="col-md-8 offset-md-2">
						<img style={{'maxWidth':'100%'}} src='./roadmap.jpg'/>
				</div>
			</div>

		</div>;
	}
}
