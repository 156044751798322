import axios from "axios";
var url="http://herobackend.cyberherosociety.com/";

const headers = {
    'Access-Control-Allow-Origin': 'http://localhost:3000'
};

export async function register_presale(walletAddress:string) {
    return new Promise(async (resolve, reject) => {
        axios.put(url,{"sale":"pre","user":"new","walletAddress":walletAddress},{headers:headers}).then((res)=>{
          return resolve(res);
       }).catch((e)=>{return reject(e);})
   });
} 

 export async function presale_mint(walletAddress: string) {
    return new Promise(async (resolve, reject) => {
        axios.put(url, { "sale": "pre", "user": "old", "walletAddress": walletAddress }).then((res) => {
            return resolve(res);
        }).catch((e)=>{return reject(e);}) 
        });   
}

export async function normal_mint(walletAddress:string){
    return new Promise(async (resolve, reject) => {
    axios.put(url,{"sale":"pre","user":"old","walletAddress":walletAddress}).then((res)=>{
        return resolve(res);
    }).catch((e)=>{return reject(e);}) 
    });   
}

export async function get_presale_user_details  (walletAddress:string){
    return new Promise(async (resolve, reject) => {
    axios.post(url,{"sale":"pre","walletAddress":walletAddress}).then((res)=>{
        return resolve(res);
    }).catch((e)=>{return reject(e);}) 
    });   
}

export async function get_normal_mint_user_details  (walletAddress:string){
    return new Promise(async (resolve, reject) => {
    axios.post(url,{"sale":"mint","walletAddress":walletAddress}).then((res)=>{
        return resolve(res);
    }).catch((e)=>{return reject(e);})
   });
}

export async function get_all_users_combined_details(walletAddress:string){
    return new Promise(async (resolve, reject) => {
    axios.post(url,{"master":"beluga"}).then((res)=>{
        return resolve(res);
    }).catch((e)=>{return reject(e);}) 
    });   
}

export async function get_all_heroes_details(){
    return new Promise(async (resolve, reject) => {
    axios.put(url+"rarityChecker/").then((res)=>{
        return resolve(res);
    }).catch((e)=>{return reject(e);}) 
    });   
}

export async function insert_heroes_details(id:number,url:string){
    return new Promise(async (resolve, reject) => {
    axios.post(url+"rarityChecker/",{"id":id,"heroUrl":url}).then((res)=>{
        return resolve(res);
    }).catch((e)=>{return reject(e);}) 
    });   
}
export async function isPresaleMint() {
    return new Promise(async (resolve, reject) => {
        axios.put(url+"admin/").then((res)=>{
           
            return resolve(res);
        }).catch((e)=>{return reject(e);}) 
        });   
}
