let roadmapElements = [
    {
      id: 1,
      title: "Pre-Sale",
      location: "Dragontail, Ascana",
      description:
        "Set up and build our community on Discord and Twitter. Build website. Beta design of comic book cover and NFTS Release lore and open whitelist (250spots, Max mint 3 per person subject to changel)",
      buttonText: "View Frontend Projects",
      date: "August 2016 - present",
      icon: "work"
    },
    {
      id: 2,
      title: "READY, SET, GO!",
      location: "Skystead, Craonia",
      description:
        "Close whitelist, finalize NFT design for the first collection. Set pre-sale S mint pricing LBB SOL subject to changes and number of NFT's in the first collection (1k subject to change .99 SOL subject to change).Launch pre-sale & minting on our website on the Solana blockchain.",
      buttonText: "View Backend Projects",
      date: "June 2013 - August 2016",
      icon: "work"
    },
    {
      id: 3,
      title: "50% SOLD",
      location: "South Warren, Geshington",
      description:
        "Establish a community wallet, NFT holders will vote on where the charl table contributions will go and lore direction. Merchandise launch for NFT holders at 50% of the cost. Marketing and social media push to sell out and help prepare for secondary market launch.        ",
      buttonText: "Company Website",
      date: "September 2011 - June 2013",
      icon: "work"
    },
    {
      id: 4,
      title: "100% SOLD",
      location: "South Warren, Geshington",
      description:
        "Use community wallet to fund charitable donations, prizes, and other parks. Perks activated buyback and burn, monthly prizes, and charitable donations. Hold and sell perk activated. Comic book development begins. Launch on secondary markets. One tree planted for every NFT sold",
      buttonText: "Course Certificate",
      date: "September 2011",
      icon: "school"
    },
    {
      id: 5,
      title: "FUTURE PLANS 2022-2023",
      location: "Skystead, Craonia",
      description:
        "Faction wars, browser basad mini game, short story/novella all based on project lore. Planning for new NFT collection (+15% mint price) Also com mmunity Input for future collections of Galaxy Celestials and Allen Villains. All subsequent pre-sele avaliability will be at a discount for current NFT holders I to be minted on our website on the Solana blockchain",
      buttonText: "College Projects",
      date: "2007 - 2011",
      icon: "school"
    },
    // {
    //   id: 6,
    //   title: "Marble Hills Grammar School",
    //   location: "Dragontail, Ascana",
    //   description:
    //     "Highschool - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec scelerisque sagittis tellus, non ultrices lacus tempus vel.",
    //   date: "2003 - 2007",
    //   icon: "school"
    // }
  ];
  
  export default roadmapElements;
  