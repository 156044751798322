// import './App.css';
// import { useMemo } from 'react';
// import * as anchor from '@project-serum/anchor';
// import Home from './MintHome';
import Main from './screens/Main';
import Mint from './screens/Mint';
import { Routes, Route } from 'react-router-dom';
import Presale from './screens/Presale';
import Lore from './screens/Lore';
import Rarity from './screens/Rarity';
// import { clusterApiUrl } from '@solana/web3.js';
// import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
// import {
//   getPhantomWallet,
//   getSlopeWallet,
//   getSolflareWallet,
//   getSolletWallet,
//   getSolletExtensionWallet,
// } from '@solana/wallet-adapter-wallets';

// import {
//   ConnectionProvider,
//   WalletProvider,
// } from '@solana/wallet-adapter-react';
// import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';

// import { ThemeProvider, createTheme } from '@material-ui/core';

// const theme = createTheme({
//   palette: {
//     type: 'dark',
//   },
// });

// const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
//   try {
//     const candyMachineId = new anchor.web3.PublicKey(
//       process.env.REACT_APP_CANDY_MACHINE_ID!,
//     );

//     return candyMachineId;
//   } catch (e) {
//     console.log('Failed to construct CandyMachineId', e);
//     return undefined;
//   }
// };

// const candyMachineId = getCandyMachineId();
// const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
// const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
// const connection = new anchor.web3.Connection(rpcHost
//   ? rpcHost
//   : anchor.web3.clusterApiUrl('devnet'));

// const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);
// const txTimeoutInMilliseconds = 30000;


const App = () => {
  
  // const endpoint = useMemo(() => clusterApiUrl(network), []);

  // const wallets = useMemo(
  //   () => [
  //     getPhantomWallet(),
  //     getSolflareWallet(),
  //     getSlopeWallet(),
  //     getSolletWallet({ network }),
  //     getSolletExtensionWallet({ network }),
  //   ],
  //   [],
  // );

  return (
    // <ThemeProvider theme={theme}>
    //   <ConnectionProvider endpoint={endpoint}>
    //     <WalletProvider wallets={wallets} autoConnect>
    //       <WalletDialogProvider>
    //         <Home
    //           candyMachineId={candyMachineId}
    //           connection={connection}
    //           startDate={startDateSeed}
    //           txTimeout={txTimeoutInMilliseconds}
    //           rpcHost={rpcHost}
    //         />

    //       </WalletDialogProvider>
    //     </WalletProvider>
    //   </ConnectionProvider>
    // </ThemeProvider>

    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="pre" element={<Presale />} />
      <Route path="lore" element={<Lore  t={0} />} />
      <Route path="/mint" element={<Mint />} />
      <Route path="/rarity" element={<Rarity />} />
    </Routes>
  );
};

export default App;

//  <ThemeProvider theme={theme}>
//  <ConnectionProvider endpoint={endpoint}>
//    <WalletProvider wallets={wallets} autoConnect>
//      <WalletDialogProvider>
//        <Home
//          candyMachineId={candyMachineId}
//          connection={connection}
//          startDate={startDateSeed}
//          txTimeout={txTimeoutInMilliseconds}
//          rpcHost={rpcHost}
//        />
      
//      </WalletDialogProvider>
//    </WalletProvider>
//  </ConnectionProvider>
// </ThemeProvider>