import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from 'react-router-dom';


export default class SectionOne extends Component {
    render() {
        return <div className="section full-height height-auto-lg hide-over background-dark-blue-3">
            <div className="hero-center-wrap relative-on-lg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-center text-lg-left parallax-fade-top align-self-center z-bigger">
                            <h2 className="text-white">Welcome to world's first CYBER HERO Society</h2>
                            <p className="mt-3 mb-4 pb-3 font-weight-normal text-white">1500 unique Heroes defending over the solana Blochchain</p>

                            {/* <a className="btn btn-primary ml-lg-0 js-tilt" href="#" role="button" data-tilt-perspective="300" data-tilt-speed="700" data-tilt-max="24"><span>Whitepaper</span></a>
                            <a className="btn btn-primary js-tilt" href="#app" data-gal="m_PageScroll2id" data-ps2id-offset="68" role="button" data-tilt-perspective="300" data-tilt-speed="700" data-tilt-max="24"><span>Download App</span></a> */}
                            
                            {/* <div className="suscribe"> */}
                                {/* <input className="form-control text-left" placeholder="Enter mint amount" type="number" name="mint" min={0} max={10} /> */}
                                <p style={{'display':'inline','color':'yellow'}} className="text-left">Dont think  just Come Let's Mint</p>
                                {/* <button type="submit" className="btn btn-primary m-0 js-tilt" data-tilt-perspective="300" data-tilt-speed="700"
                                    data-tilt-max="24"><span>Mint</span></button> */}
                                <Link to='/mint'><a  className="btn btn-primary js-tilt" href="#app" role="button" data-tilt-perspective="300" data-tilt-speed="700" data-tilt-max="24" ><span>Mint-Now</span></a></Link>
                                    
                            {/* </div> */}
                            <br/><br />
                            <span>
                                <h6 style={{'display':'inline','color':'yellow'}}>Want to register in Pre-Sale..!   </h6>
                                <Link to='/pre'><a className="btn btn-primary js-tilt" href="#app" role="button" data-tilt-perspective="300" data-tilt-speed="700" data-tilt-max="24" ><span>Pre-Sale</span></a></Link></span>
                            <p className="text-left text-white mb-0"><small></small></p>
                        </div>
                        <div className="col-lg-6 mt-5 mt-lg-0 rounded-500">
                            <Carousel
                                className='mt-5'
                                autoPlay={true}
                                centerMode={false}
                                infiniteLoop
                                interval={1500}
                                showThumbs={false}
                                showStatus={false}
                                showArrows={false}>
                                <div >
                                    <img className='rounded' src="./1.png" />
                                </div>
                               
                                <div >
                                    <img className='rounded'src="./16.png" />
                                </div>
                               
                                <div >
                                    <img className='rounded' src="./6.png" />
                                </div>
                               
                                <div>
                                    <img  className='rounded' src="./12.png" />
                                </div>
                               
                                <div>
                                    <img className='rounded' src="./10.png" />
                                </div>
                               
                                <div>
                                    <img className='rounded' src="./8.png" />
                                </div>
                               
                                <div>
                                    <img className='rounded' src="./14.png" />
                                </div>
                               
                                <div>
                                    <img className='rounded' src="./4.png" />
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="ocean dark">
                <div className="wave"></div>
                <div className="wave"></div>
            </div>
            <div id="particles-js" className="min-height"></div> */}

            
        </div>
            
    }
}
