export function hideCanvas(){
    try{
        document.getElementById('bgCanvas').style.display = "none"
        document.getElementById('terCanvas').style.display = "none"
      }
      catch(e){

      }
}
export function showCanvas(){
    try{
        document.getElementById('bgCanvas').style.display = "block"
        document.getElementById('terCanvas').style.display = "block"
      }
      catch(e){

      }
}