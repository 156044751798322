import React, { Component, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import { hideCanvas } from '../other/helper';
import FAQ from './FAQ';
import Footer from './Footer';
import Header from './Headerrr';

export default class Rarity extends React.Component<{}, { id: string,show:boolean }>  {

    constructor(props: any) {
        super(props);
       
        this.state = {
            id: "0",
            show:false
        };
       
    }


    handleclick(lid: string) {
        console.log("check", lid);
        this.setState({show:!this.state.show})
    }
    render() {
        hideCanvas()
        return <div style={{ 'margin': 0, 'padding': 0 }}>
            <Header />
            <div className="padding-top-bottom-big background-dark-blue-3" style={{ 'paddingTop': 130, 'margin': 0 }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-wrap text-center">
                                <div className="back-title">Rarity Checker</div>
                                <h3 className="text-white">Rarity Checker</h3>
                                <p className="color-pr">1500 unique Heroes defending over the solana Blochchain</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">

                        <div className='col-md-3'></div>
                        <div className="col-md-6 align-self-center">
                            <h4 className="text-white">Determine the rairty of your Hero</h4>
                            <p className="lead text-grey">Enter your  Heroe's ID number:</p> <div className="suscribe">
                                <input className="form-control text-left" placeholder="Enter ID" type="number" name="mint" onChange={(e) => {
                                    this.setState({ id: e.target.value });

                                }} />
                                <button type="submit" className="btn btn-primary m-0 js-tilt" data-tilt-perspective="300" data-tilt-speed="700"
                                    data-tilt-max="24" onClick={() => { this.handleclick(this.state.id) }}  ><span>Check</span></button></div>

                        </div>
                    </div>


                </div>;
                {
                    this.state.show && <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-center  align-self-center z-bigger">
                                    <img style={{'maxWidth':350,'maxHeight':380}} className='rounded' src="./1.png" />
                            
                        </div>
                        <div className="col-lg-6 mt-5 pl=2 mt-lg-0 rounded-500">
                        {/* <a className='btn btn-primary' href="#app" role="button" data-tilt-perspective="300" data-tilt-speed="700" data-tilt-max="24"><span>Pre-sale</span></a> */}
                                    <h1 className="text-white">CYBER HERO#0</h1>
                            {/* <p className="mt-3 mb-4 pb-3 font-weight-normal text-white">1500 unique Heroes defending over the solana Blochchain</p>

                                <p style={{'display':'inline','color':'yellow'}} className="text-left">Dont think  just Come Let's Mint</p>
                                     */}
                            <br/>
                                {/* <h6 style={{'display':'inline','color':'yellow'}}>Want to register in Pre-Sale..!   </h6> */}
                        {/* <a className='btn btn-secondary mt-1'><span>Hero Background Color :Yellow</span></a> */}
                        <a className='btn btn-light mt-1'><span>Hero Background Color :Yellow</span></a>
                        <a className='btn btn-light mt-1'><span>Hero Weapon:IR Blaster</span></a>
                        <a className='btn btn-light mt-1'><span>Hero Energy:Gas</span></a>
                            <p className="text-left text-white mb-0"></p>
                            {/* <p className="text-left text-white mb-0"><small>hhhh</small></p> */}
                               
                        </div>
                    </div>
                </div>
                }
                {!this.state.show && <><br /><br /><br /><br /><br /><br /><br /></>}
            </div>
           <Footer/>


        </div>;
    }
}