import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import StatusAlert, { StatusAlertService } from 'react-status-alert';

import Wallet from './Wallet';
import Footer from './Footer';
import FAQ from './FAQ';
import Header from './Headerrr';

// import Header from './Header';

export default class Mint extends Component {
    render() {
        return <div>

            {/* header separate for min page as in the previous pro absence of router and usage of href tags separate header is being used rather than disturbing everything  */}
           
            <Header></Header>
            <div className="section full-height height-auto-lg hide-over background-dark-blue-3 ">
            <div className="hero-center-wrap relative-on-lg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-center text-lg-left parallax-fade-top align-self-center z-bigger">
                        <h2 className="text-white">Connect wallet and  Mint</h2>
                            <Wallet />
                        </div>
                        <div className="col-lg-6 mt-5 mt-lg-0 rounded-500">
                            <Carousel
                               className='mt-5'
                                autoPlay={true}
                                centerMode={false}
                                infiniteLoop
                                interval={1500}
                                showThumbs={false}
                                showStatus={false}
                                showArrows={false}>
                                  
                                <div >
                                    <img className='rounded' src="./1.png" />
                                </div>
                               
                                <div >
                                    <img className='rounded'src="./16.png" />
                                </div>
                               
                                <div >
                                    <img className='rounded' src="./6.png" />
                                </div>
                               
                                <div>
                                    <img  className='rounded' src="./12.png" />
                                </div>
                               
                                <div>
                                    <img className='rounded' src="./10.png" />
                                </div>
                               
                                <div>
                                    <img className='rounded' src="./8.png" />
                                </div>
                               
                                <div>
                                    <img className='rounded' src="./14.png" />
                                </div>
                               
                                <div>
                                    <img className='rounded' src="./4.png" />
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
           
            <div id="particles-js" className="min-height"></div>
        </div>

           
                                <div className="">
                                  
                
                <StatusAlert />
            </div>
            {/* <FAQ /> */}
            <Footer />
        </div>
    }
}